import request from '../utils/clientRequest.js';

export default {
	getChannelDetail: function(id) {
		return request({
			url: 'content/channel?id=' + id,
			method: 'get',
		})
	}
};
