<template>
	<div>
		<subnav :subs="subs"></subnav>
		<div class="content" v-if='hasData&&!loading'>
			<!--
			<div class="content-top">
				<div class="content-intro">
					<img class="content-intro-image" :src="prevImage" fit="cover" />
					<div class="content-intro-text">{{intro}}</div>
				</div>
				<div class="content-detail-title">
					<span>详细介绍</span>
				</div>
			</div>
-->
			<div class="content-detail-richtext rich-text" v-html="richtext">
			</div>
		</div>
		<div v-if="!hasData&&!loading" class="content-empty">
			版块正在建设中...
		</div>
		<div v-if="loading" class="content-empty">
			页面加载中...
		</div>
	</div>
</template>

<script>
	import api from "@/api/channel"
	import SubNavbar from "@/components/common/SubNavbar"
	export default {
		name: 'ContentPage',
		data() {
			return {
				subs: [],
				loading: false,
				hasData: true,
				prevImage: "",
				intro: "",
				richtext: ""
			}
		},
		mounted() {
			this.init();
		},
		watch: {
			$route() {
				this.init();
			}
		},
		methods: {
			init: function() {
				this.loading = true;
				this.hasData = true;
				this.prevImage = "";
				this.intro = "";
				this.richtext = "";
				this.loading = true;
				this.genSubNavbar();
				this.getData(this.$route.name);
			},
			genSubNavbar: function() {
				let self = this;
				var queryData = this.$router.options.routes.filter(function(_data) {
					return _data.meta != null && _data.meta.index == self.$route.meta.index
				});
				this.subs = queryData;
			},
			getData: function(name) {
				var self = this;
				api.getChannelDetail(name).then(function(resp) {
					self.loading = false;
					if (resp.success) {
						if (resp.data != null) {
							self.prevImage = resp.data.prevImage;
							self.intro = resp.data.intro;
							self.richtext = resp.data.content;
						} else {
							self.hasData = false;
						}

					}
				});

			}
		},
		components: {
			"subnav": SubNavbar
		}
	}
</script>

<style lang="scss" scoped="true">
	.content {
		display: flex;
		align-items: center;
		flex-direction: column;

		.content-top {
			border-top: 1px solid $primary-font-color-disabled;
			border-bottom: 1px solid $primary-font-color-disabled;

			.content-intro {
				width: 1100px;
				display: flex;
				padding: 20px 0 20px 0;

				.content-intro-image {
					width: 550px;
					flex-shrink: 0;
				}

				.content-intro-text {
					color: #626262;
					flex-grow: 1;
					text-align: left;
					text-indent: 2em;
					line-height: 1.5em;
					margin-left: 2em;
					border-left: 1px solid #e1e1e1;
					padding-left: 2em;
				}
			}

			.content-detail-title {
				text-align: left;

				span {
					color: $primary-font-color-light;
					padding: 5px 1em;
					border: 1px solid $primary-font-color-disabled;
					border-radius: 2em;
					margin-bottom: 20px;
					display: inline-block;
				}
			}
		}

		.content-detail-richtext {
			width: 1100px;
			text-align: left;
			padding: 1em;
		}
	}

	.content-empty {
		padding: 160px 0 200px 0;
		text-align: center;
		font-size: 2em;
		color: $primary-font-color-disabled;
	}
</style>
<style lang="scss">
	.rich-text{
		img{
			max-width: 100% !important;
		}
	}
</style>
